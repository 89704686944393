<template>
    <form class="form" @submit.prevent="sinUp">
        <div class="form__two-fields-form">
            <div class="form__input-form">
                <label class="form__input-label form--required">Email</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/mdi_email-outline.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your email" class="form__input form--input-padding-left"
                        v-model="signUpModel.email">
                </div>

                <div class="form__input-error" v-if="errors.fields.email">
                    {{ errors.fields.email[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Username</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/fluent_person-12-regular.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your username" class="form__input form--input-padding-left"
                        v-model="signUpModel.userName">
                </div>

                <div class="form__input-error" v-if="errors.fields.userName">
                    {{ errors.fields.userName[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Password</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/solar_lock-password-linear.svg" class="form__input-img-icon" alt="" />
                    <input type="password" placeholder="Enter your password"
                        class="form__input form--input-padding-left" v-model="signUpModel.password">
                </div>

                <div class="form__input-error" v-if="errors.fields.password">
                    {{ errors.fields.password[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Confirm Password</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/solar_lock-password-linear.svg" class="form__input-img-icon" alt="" />
                    <input type="password" placeholder="Confirm your password"
                        class="form__input form--input-padding-left" v-model="signUpModel.password_confirmation">
                </div>

                <div class="form__input-error" v-if="errors.fields.password_confirmation">
                    {{ errors.fields.password_confirmation[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Full Name</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/fluent_person-12-regular.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your name" class="form__input form--input-padding-left"
                        v-model="signUpModel.name">
                </div>

                <div class="form__input-error" v-if="errors.fields.name">
                    {{ errors.fields.name[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Phone</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/ph_phone-bold.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your phone" class="form__input form--input-padding-left"
                        v-model="signUpModel.phone">
                </div>

                <div class="form__input-error" v-if="errors.fields.phone">
                    {{ errors.fields.phone[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Company</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/ri_hotel-line.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your company" class="form__input form--input-padding-left"
                        v-model="signUpModel.company">
                </div>

                <div class="form__input-error" v-if="errors.fields.company">
                    {{ errors.fields.company[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Hotel Role</label>

                <div class="form__input-icon">
                    <img src="@/assets/icons/vaadin_user-card.svg" class="form__input-img-icon" alt="" />
                    <input type="text" placeholder="Enter your hotel role" class="form__input form--input-padding-left"
                        v-model="signUpModel.hotelRole">
                </div>
                
                <div class="form__input-error" v-if="errors.fields.hotelRole">
                    {{ errors.fields.hotelRole[0] }}
                </div>
            </div>

            <button class="button button--primary auth__right-button-resize" v-if="!buttonLoader">
                Sign Up
                <img src="@/assets/icons/ph_arrow-right.svg" class="button__icon" alt="" />
            </button>

            <ButtonTextLoader button-class="button button--primary auth__right-button-resize"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { reactive, ref } from 'vue'
import { SignUpServices } from '../Services/SignUpServices.js'
import { useClientStore } from '@/stores/ClientStore.js'
import { useSubscriptionsStore } from '@/stores/SubscriptionStore.js'
import { useHotelStatusStore } from "@/stores/HotelStatusStore.js"
import { useRouter } from 'vue-router'
import Notifications from '../../../common/Notifications.vue'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import axios from 'axios'

const { clientSingUpService, errors } = SignUpServices()
const { client } = useClientStore()
const { checkProsafeLicenceStatus } = useSubscriptionsStore()
const { getHotelStatus } = useHotelStatusStore()
const notification = ref(null)
const router = useRouter()
const buttonLoader = ref(false)
const signUpModel = reactive({
    email: '',
    userName: '',
    password: '',
    password_confirmation: '',
    name: '',
    phone: '',
    company: '',
    hotelRole: ''
})

async function sinUp() {
    buttonLoader.value = true

    const response = await clientSingUpService(signUpModel)

    if (!response.hasErrors) {
        // * Set clien data to local storage
        client.id = response.data.userId
        client.rolId = response.data.rolId
        client.name = response.data.name;
        client.image = response.data.photo;
        client.hotelId = null;
        client.hotel = null;
        client.isAuth = true;

        // * Check ProSafe licence and set data to local storage
        await checkProsafeLicenceStatus()

        // * Check hotel setup status
        await getHotelStatus()
    } else {
        buttonLoader.value = false
        notification.value.sendNotification('error', 'Error', response.message);
    }
}
</script>