import { httpRequests } from "../../../helpers/HttpRequests";

export function SignUpServices() {
    const { postRequest, httpErrors } = httpRequests();
    const errors = httpErrors;

    function clientSingUpService(signUpModel) {
        return postRequest('/web_sign_up', signUpModel);
    }

    return { clientSingUpService, errors }
}